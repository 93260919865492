<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      جميع الحقوق محفوظة لدى
      <img
        :src="require('@/assets/images/tahdir.png')"
        style="height: 20px"
        alt=""
      />
      <a href="https://tahdir.net" target="_blank">التحضير الذكي</a>
      ، من برمجة وتطوير شركة
      <b-link class="ml-25" href="https://brmja.com" target="_blank">
        <img
          :src="require('@/assets/images/brmja.svg')"
          style="height: 20px; position: relative; top: 2px !important"
          alt=""
        />
        برمجة دوت كوم</b-link
      >
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
