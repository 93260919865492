export default [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'استيراد الطلاب',
    route: 'import',
    icon: 'UploadIcon',
  },
  {
    title: 'توزيع اللجان',
    route: 'create',
    icon: 'GridIcon',
  },
  {
    title: 'سجل عمليات التوزيع',
    route: 'history',
    icon: 'ListIcon',
  },
  {
    title: 'النماذج والكشوف',
    route: 'kushof',
    icon: 'PrinterIcon',
  },
  {
    title: 'الاعدادات',
    route: 'settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'شروحات النظام',
    icon: 'VideoIcon',
    href: 'https://www.youtube.com/playlist?list=PLEurVG9qb-Cr30Ib4WfwVdKJw3NXB4RTA'
  },
]
